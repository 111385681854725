import React, { useState } from "react";
import { useFormik } from "formik";

const UploadPictureFormik = ({apo, dn, picture = null}) => {
    const [message, setMessage] = useState("")
  const [userPicture, setUserPicture] = useState(picture)
  const [preview, setPreview] = useState(null)

  function gfg_Run(_date) {
    if (_date.toJSON() === null) return ""
    let date = _date.toJSON().slice(0, 10)
    return date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4)
  }

  const formik = useFormik({
    initialValues: {
      picture: null,
    },
    onSubmit: async (values) => {
      const formData = new FormData()
      formData.append("picture", values.picture)
      let dateObject = new Date(dn)
      formData.append("apo", apo)
      formData.append("dn", gfg_Run(dateObject))

      var myHeaders = new Headers()
      myHeaders.append("Accept", "application/json")
      myHeaders.append("origin", "https://api.flshbm.ma")

      const url = "https://api.flshbm.ma/v1/student/upload-picture"

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: formData,
          redirect: "follow",
        })

        const result = await response.json()

        if (response.ok) {
          setMessage(result.message)
          setPreview(null)
          setUserPicture(result.path)
          formik.resetForm()
        } else {
          setMessage(result.message || "An error occurred during upload.")
        }
      } catch (error) {
        setMessage("An error occurred during upload.")
      }
    },
  })

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0]
    formik.setFieldValue("picture", file)

    if (file) {
      const reader = new FileReader()
      reader.onload = () => setPreview(reader.result)
      reader.readAsDataURL(file)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.files[0]
    formik.setFieldValue("picture", file)
    
    if (file) {
      const reader = new FileReader()
      reader.onload = () => setPreview(reader.result)
      reader.readAsDataURL(file)
    }
  }


    return (
        <div style={{ width: '100%', maxWidth: '24rem', margin: '0 auto' }}>
          {userPicture ? (
            <div style={{ padding: '1rem', borderRadius: '0.5rem' }}>
              <img
                src={`https://api.flshbm.ma/storage/${userPicture}`}
                alt="Uploaded"
                style={{ width: '100%', height: 'auto', borderRadius: '0.5rem', maxHeight: '150px', maxWidth: '150px' }}
              />
            </div>
          ) : (
            <div style={{ padding: '1.5rem' }}>
              {message && (
                <p style={{ marginBottom: '1rem', fontSize: '0.875rem', textAlign: 'center', color: '#6b7280' }}>
                  {message}
                </p>
              )}
      
              <form onSubmit={formik.handleSubmit} style={{ display: 'space-between', gap: '1.5rem' }}>
                {!preview && !userPicture && (
                    <div
                    style={{
                      border: '2px dashed #e5e7eb',
                      borderRadius: '0.5rem',
                      padding: '2rem',
                      textAlign: 'center',
                      cursor: 'pointer',
                      transition: 'border-color 0.2s ease',
                      hover: { borderColor: '#3b82f6' },
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <input
                      id="picture"
                      name="picture"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <label
                      htmlFor="picture"
                      style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <svg className="h-12 w-12 text-muted-foreground" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#e2e2e2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 3v18h14V3H5zM5 3h14M12 15V8m0 0l-3 3m3-3l3 3" />
                      </svg>
                      <div style={{ marginTop: '0.5rem' }}>
                        <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>
                        Sélectionnez votre photo ou glissez-la ici
                        </p>
                      </div>
                      <button type="button" style={{
                        backgroundColor: '#e5e7eb', padding: '0.5rem 1rem', borderRadius: '0.375rem', border: 'none', cursor: 'pointer', marginTop: '0.5rem', textAlign: 'center'
                      }}>
                        Sélectionnez votre photo
                      </button>
                    </label>
                  </div>
                )}
      
                {preview && (
                  <div style={{ marginTop: '1rem' }}>
                    <p style={{ fontSize: '0.875rem', fontWeight: '500' }}>Preview:</p>
                    <img
                      src={preview}
                      alt="Preview"
                      style={{ width: '100%', height: 'auto', borderRadius: '0.5rem', maxHeight: '150px', maxWidth: '150px' }}
                    />
                  </div>
                )}
      
                <button
                  type="submit"
                  style={{
                    width: '100%',
                    backgroundColor: formik.isSubmitting ? '#e5e7eb' : '#3b82f6',
                    color: '#fff',
                    padding: '1rem',
                    borderRadius: '0.375rem',
                    border: 'none',
                    cursor: formik.values.picture && !formik.isSubmitting ? 'pointer' : 'not-allowed',
                    marginTop: '1rem',
                  }}
                  disabled={!formik.values.picture || formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Uploading..." : "Upload"}
                </button>
              </form>
            </div>
          )}
        </div>
      )
      
};

export default UploadPictureFormik;
